<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <!-- 범위 날짜 검색 -->
          <li class="field">
            <div class="title">작업 일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range type="lookup-condition"  v-model="searchFilterOptions.dlivyDate"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">장비구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    ref="searchEqpDiv"
                    v-model="searchFilterOptions.eqpDiv"
                    :dataSource="commonCodeOptions.eqpDiv"
                    :allowFiltering="false"
                    :fields="commonCodeOptions.fields"
                    cssClass="lookup-condition-dropdown"
                >
                </ejs-dropdownlist>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">장비명</div>
            <ul class="content">
              <li class="item">
                <input-text ref="searchEqpName" v-model="searchFilterOptions.eqpName" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>
    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article" :class="isOpen ? 'detail-view-opened' : ''">
        <!-- ######## 목록 리스트 ########-->
        <div class="article-left">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{ gridField.title }}</div>
                <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="add">
                    <erp-button button-div="GET" :is-icon-custom="true" @click.native="onClickAdd">추가</erp-button>
                  </li>
                  <li class="print">
                    <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="grid"
                    v-bind="initGrid"
                    @recordClick="onGridClick"
                    @queryCellInfo="onQueryCellInfo"
                    @headerCellInfo="onHeaderCellInfo"
                    @actionComplete="gridComplete"/>
              </div>
            </div>
          </section>
        </div>
        <!-- ######## 상세본문 ########-->
        <div class="article-right">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">작업상세</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="save keyColor">
                    <erp-button button-div="SAVE" :is-key-color="true" :is-shortcut-button="true" :ignore="!isOpen || isPopupOpened" @click.native="onClickSave">저장</erp-button>
                  </li>
                  <li class="delete">
                    <erp-button  v-if="!isNew" button-div="DELETE" :is-key-color="true" :is-shortcut-button="true" :ignore="!isOpen || isPopupOpened" @click.native="onClickDelete">삭제</erp-button>
                  </li>
                  <li class="close">
                    <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
                  </li>
                </ul>
              </div>
            </div>

            <div class="section-body">
              <div class="article-section" style="height: 180px;">
                <div class="body-data">
                  <div class="data-outer">
                    <div class="data-inner">
                      <ul class="data-content">
                        <!-- detail field -->
                        <li class="field">
                          <div class="title">작업 번호</div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-text ref="eqpDlivyId" v-model="detailData.eqpDlivyId" :readonly="true"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title required">작업 일자</div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-date ref="dlivyDate" type="lookup-condition"  :disabled="!isNew" v-model="detailData.dlivyDate"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li class="field correspondent">
                          <!-- 필수 : required -->
                          <div class="title required">장비명</div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group input">
                                <div class="form">
                                  <input-text ref="eqpName" id="eqpName" v-model="detailData.eqpName" @keydown.enter="onClickPopupSearch()" :readonly="true"/>
                                </div>
                              </li>
                              <li class="item form-group button">
                                <ul class="button">
                                  <li class="search">
                                    <erp-button button-div="GET" :ignore="isPopupOpened" @click.native="onClickPopupSearch()">검색</erp-button>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title" >장비 구분</div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <ejs-dropdownlist ref="eqpDiv" v-model="detailData.eqpDiv"
                                                    :dataSource="commonCodeOptions.eqpDiv" :fields="commonCodeOptions.fields"
                                                    :allowFiltering="false"  cssClass="body-data-dropdown" :readonly="true"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field  correspondent voc-section">
                          <div class="title required">작업명</div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group input">
                                <div class="form">
                                  <input-text ref="workName" id="workName"  v-model="detailData.workName" @keydown.enter="onClickWorkTreePopupShow"  :readonly="true"/>
                                </div>
                              </li>
                              <li class="item form-group button">
                                <ul class="button">
                                  <li class="search"><erp-button button-div="GET" @click.native="onClickWorkTreePopupShow">검색</erp-button></li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li class="field">
                          <div class="title">등록자</div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group" >
                                <div class="form" >
                                  <input-text  ref="insertName" v-model="detailData.insertName" :readonly="true"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field required">
                          <div class="title">출/입 시간</div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group time">
                                <div class="form">
                                  <input-time v-model=" detailData.startTime" :format="'HH:mm'"/>
                                </div>
                              </li>
                              <li class="item text">~</li>
                              <li class="item form-group time">
                                <div class="form">
                                  <input-time v-model=" detailData.endTime" :format="'HH:mm'"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field remarks voc-section">
                          <div class="title">비고</div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-textarea ref="remarks"  :rows="4" v-model="detailData.remarks"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="article-section" style="height:calc(100% - 530px)">
                <div class="section-body">
                  <div class="article-section">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">{{gridField2.title}}</div>
                        <div class="header-caption">[ {{ gridField2.count }} 건 ]</div>
                      </div>
                      <div class="header-right">
                        <ul class="header-button">
                          <li class="add">
                            <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="onClickAddSupplies">행추가</erp-button>
                          </li>
                          <li class="delete">
                            <erp-button button-div="DELETE"  @click.native="onClickDelSupplies">행삭제</erp-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="section-body" style="border: none;">
                      <div class="body-grid table-side">
                        <ejs-grid-wrapper
                            ref="grid2"
                            v-bind="initGrid2"
                            @headerCellInfo="onHeaderCellInfo2"
                            @queryCellInfo="onQueryCellInfo2"
                            @actionComplete="gridComplete2"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="article-section" style="height: 350px;">
                <div class="section-body">
                  <div class="article-left" style="width: 50%;">
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">{{gridField3.title}}</div>
                        </div>
                        <div class="header-right"> </div>
                      </div>
                      <div class="section-body" style="border: none;">
                        <div class="body-grid">
                          <ejs-grid-wrapper
                              ref="grid3"
                              v-bind="initGrid3"
                              @headerCellInfo="onHeaderCellInfo3"
                              @queryCellInfo="onQueryCellInfo3"
                              @actionComplete="gridComplete3"
                             />
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="article-left" style="width: 50%;">
                    <section class="article-section" >
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">{{gridField4.title}}</div>
                        </div>
                        <div class="header-right">
                          <ul class="header-button">
                            <li class="add">
                              <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="onClickAddWorker">행추가</erp-button>
                            </li>
                            <li class="delete">
                              <erp-button button-div="DELETE"  @click.native="onClickDelWorker">행삭제</erp-button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="section-body" style="border: none;">
                        <div class="body-grid">
                          <ejs-grid-wrapper
                              ref="grid4"
                              v-bind="initGrid4"
                              @headerCellInfo="onHeaderCellInfo4"
                              @queryCellInfo="onQueryCellInfo4"
                              @actionComplete="gridComplete4"/>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </div>
    <!-- 팝업 -->
<!--    장비명 찾기 팝업-->
    <eqp-search-popup
        ref="eqpSearchPopup"
        v-if="isShowSearchPopup"
        @popupConfirmed="onPopupConfirmed"
    />
<!--    -->
    <tree-popup
        ref="workTreePopup"
        v-if="isShowTreePopup"
        @popupClosed="onTreePopupClose"
        @popupConfirm="onTreePopupConfirm"
    />
    <supplies-stock-search-popup
        ref="suppliesStockSearchPopup"
        v-if="isShowSearchPopup2"
        @popupConfirmed="onPopupConfirmed2"
    />
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText.vue";
import InputDate from "@/components/common/datetime/InputDate.vue";
import {compareDate, getFormattedDate} from "@/utils/date";
import StockEquipmentMng from "@/api/v2/StockManage/StockEquipmentMng";
import inputDateRange from "@/components/common/datetime/InputDateRange.vue";
import moment from "moment/moment";
import {validateFormRefs} from "@/utils/formUtil";
import eqpSearchPopup from "@/views/stock-management/common/EquipmentSearchPopup.vue";
import TreePopup from "@/views/stock-management/common/StockTreePopup.vue";
import StockCommon from "@/api/v2/StockManage/StockCommon";
import InputTime from "@/components/common/datetime/InputTime.vue";
import {mapGetters} from "vuex";
import suppliesStockSearchPopup from "@/views/stock-management/common/SuppliesStockSearchPopup.vue";
import InputTextarea from "@/components/common/text/InputTextarea.vue";

export default {
  name: "EquipmentWorkRegistration",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {
    InputTextarea,
    suppliesStockSearchPopup,
    InputTime,
    TreePopup,
    eqpSearchPopup,
    inputDateRange, EjsGridWrapper, ErpButton, ReportView,
    InputText,
    InputDate,

  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //신규,수정 여부
      isNew: false,
      //팝업 오픈 여부
      isOpen: false,

      isShowSearchPopup:false,
      isShowSearchPopup2: false,
      isShowTreePopup:false,

      //조회 필드
      searchFilterOptions: {
        dlivyDate: {
          //from: getAddDays(new Date(),-30),
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
        fromDate: "",
        toDate: "",
        eqpName: "",
        eqpDiv: "",
      },
      //드롭다운 코드
      commonCodeOptions: {
        eqpDiv: commonCodesGetCommonCode("EQP_DIV", false, true, "", ""),
        fields: {text: "comName", value: "comCode"},
      },
      //정합성 체크 항목
      validateRefField: {
        "eqpName": {required: true},
        "dlivyDate": {required: true},
        "workName": {required: true},
      },

      //그리드 필드
      gridField : {title: "작업목록", count: 0, dataSource: [],},
      gridField2: {title: "출고품목", count: 0, dataSource: [],},
      gridField3: {title: "작업지역", count: 0, dataSource: [],},
      gridField4: {title: "근무자",   count: 0, dataSource: [],},


      //상세 데이터 필드
      detailData: {
        eqpDlivyId: null,
        eqpCode: null,
        eqpDiv: null,
        eqpName: null,
        workCode: null,
        workName: null,
        dlivyDate: null,
        startTime: null,
        endTime: null,
        remarks: null,
        delFlag: null,
        insertId: null,
        insertName:null,
        insertDt: null,
        updateId: null,
        updateDt: null,
        //출고품목
        dtlListD:[],
        dtlListU:[],
        dtlListI:[],
        //근무자
        dtl3ListD:[],
        dtl3ListU:[],
        dtl3ListI:[],
        //작업지역
        dtl2ListU:[],
      },
    };

  },



  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    ...mapGetters(["userIp","userId","username"]),
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return (
          this.isShowSearchPopup||this.isShowTreePopup||this.isShowSearchPopup2
      );
    },
    initGrid() {
      return {
        columns: [
          {field: "eqpDlivyId", textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string", headerText: "작업번호", isPrimaryKey: true,},
          {field: "dlivyDate",  textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string", headerText: "작업일자",},
          {field: "eqpDiv",     textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string", headerText: "장비구분", isCommonCodeField: true, dataSource: this.commonCodeOptions.eqpDiv},
          {field: "eqpName",    textAlign: 'center', allowEditing: false, width: 200, visible: true, type: "string", headerText: "장비명",},
          {field: "workCode",   textAlign: 'center', allowEditing: false, width: 200, visible: true, type: "string", headerText: "작업명",},
          {field: "startTime",  textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string", headerText: "출고시간",},
          {field: "endTime",    textAlign: 'center', allowEditing: false, width: 100, visible: true, type: "string", headerText: "입고시간",},
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: {pageSize: 50},
        resizeSettings: {mode: "Normal"},
      };
    },
    initGrid2() {
      return {
        columns: [
          {field: "dtlId",        textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string", headerText: "key", isPrimaryKey: true,},
          {field: "wrhousCode",   textAlign: 'center', allowEditing: false, width: 30,  visible: true,  type: "string", headerText: "창고코드", },
          {field: "wrhousName",   textAlign: 'center', allowEditing: false, width: 70,  visible: true,  type: "string", headerText: "창고", },
          {field: "suppliesCode", textAlign: 'center', allowEditing: false, width: 70,  visible: true,  type: "number", headerText: "코드",},
          {field: "suppliesName", textAlign: 'center', allowEditing: false, width: 100, visible: true,  type: "string", headerText: "저장품명",},
          {field: "qty",          textAlign: 'right',  allowEditing: true,  width: 50,  visible: true,  type: "number", headerText: "수량",isNumericType: true,  inputNumberProperty: {allowDot:true ,maxUnderDotLength: 3 },},
          {field: "unit",         textAlign: 'left', allowEditing: false, width: 50,  visible: true,  type: "string", headerText: "단위",},
          {field: "remarks",      textAlign: 'left',   allowEditing: true,  width: 170, visible: true,  type: "string", headerText: "비고",},
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField2.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: {pageSize: 50},
        //그리드 정합성 체크
        validationRules: {
          wrhousName:  {required: true,},
          qty:     {required: true, min:1},
          suppliesCode: {
            required: true,
            custom: {
              method: (record) => {
                if(this.$refs.grid2.getBatchCurrentViewRecords().filter(v => v.wrhousCode === record.wrhousCode && v.suppliesCode === record.suppliesCode).length>1) {
                  return;
                }
                return true;
              },
              message: "중복된 창고,저장품이 있습니다.",
            },
          },

        },
      };
    },
    initGrid3() {
      return {
        columns: [
          {field: "areaCode", textAlign: 'center', allowEditing: false, width: 0,  visible: false, type: "string",  headerText: "ID", isPrimaryKey: true,},
          {field: "areaName", textAlign: 'center', allowEditing: false, width: 80, visible: true,  type: "string",  headerText: "작업지역",},
          {field: "checked",  textAlign: 'center', allowEditing: true,  width: 20, visible: true,  type: "boolean", headerText: "사용여부", displayAsCheckBox: true, editType: "booleanedit" },

        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField3.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: {pageSize: 50},
      };
    },
    initGrid4() {
      return {
        columns: [
          {field: "dtlId",    textAlign: 'center', allowEditing: false, width: 30, visible: false,  type: "string", headerText: "dtlId", isPrimaryKey: true,},
          {field: "wrkrName", textAlign: 'center', allowEditing: true,  width: 80, visible: true,   type: "string", headerText: "근무자명",},
          {field: "empno",    textAlign: 'center', allowEditing: true,  width: 80, visible: true,   type: "string", headerText: "사번"},
          {field: "dept",     textAlign: 'center', allowEditing: true,  width: 80, visible: true,   type: "string", headerText: "부서",},
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField4.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: {pageSize: 50},
        //그리드 정합성 체크
        validationRules: {
          wrkrName:  {required: true, },
        },
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
    // 기초 데이터 초기화
    this.clearDetailData();
  },
  async mounted() {
    //기초데이터 세팅
    this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //기초데이터 세팅
    initData(){
      //달력 min,max 설정
      const datePickerInstance = this.$refs.dlivyDate.$refs.datepicker.ej2Instances;
      //datePickerInstance.min = await StockCommon.getLastSuppliesClosingYm();// 최종 마감일자
      datePickerInstance.max = getFormattedDate(new Date());
    },
    //필수입력체크
    validateFormRefs,
    //region ******************************* API *******************************
    async apiList(){
      let args = {
        fromDate: this.searchFilterOptions.dlivyDate.from ,
        toDate:this.searchFilterOptions.dlivyDate.to,
        eqpName: this.searchFilterOptions.eqpName,
        eqpDiv: this.searchFilterOptions.eqpDiv,
      };
      this.gridField.dataSource = await StockEquipmentMng.getEqpDlivyList(args);
    },
    async apiDetail(eqpDlivyId){
      const args = {eqpDlivyId: eqpDlivyId};
      this.detailData = await StockEquipmentMng.getEqpDlivyDetail(args);
      this.gridField2.dataSource = await StockEquipmentMng.getEqpDlivySupplieseList(args);
      this.gridField3.dataSource = await StockEquipmentMng.getEqpDlivyAreaList(args);
      this.gridField4.dataSource = await StockEquipmentMng.getEqpDlivyWrkrList(args);
    },

    async apiSave(){
      // 출고 품목
      this.detailData.dtlListD = this.$refs.grid2.getBatchChanges().deletedRecords;
      this.detailData.dtlListU = this.$refs.grid2.getBatchChanges().changedRecords;
      this.detailData.dtlListI = this.$refs.grid2.getBatchChanges().addedRecords;
      //작업지역
      this.detailData.dtl2ListU = this.$refs.grid3.getBatchChanges().changedRecords;
      //근무자
      this.detailData.dtl3ListD = this.$refs.grid4.getBatchChanges().deletedRecords;
      this.detailData.dtl3ListU = this.$refs.grid4.getBatchChanges().changedRecords;
      this.detailData.dtl3ListI = this.$refs.grid4.getBatchChanges().addedRecords;
      //3. 저장처리
      if (this.isNew) {
        await StockEquipmentMng.postEqpDlivyDetail(this.detailData);
      } else {
        await StockEquipmentMng.patchEqpDlivyDetail(this.detailData);
      }

    },
    async apiDelete(){
      await StockEquipmentMng.deleteEqpDlivyDetail({deleteKey:this.detailData.eqpDlivyId, deleteDate:this.detailData.dlivyDate});
    },
    //endregion
    //region ******************************* 버튼 이벤트 *******************************
    //조회
    async onClickSearch() {
      await this.onClickClose();
      await this.apiList();
    },
    //추가
    async onClickAdd() {
      this.isOpen = true;
      this.isNew = true;
      await this.clearDetailData();
      this.gridField3.dataSource = commonCodesGetCommonCode("EQP_WORK_AREA", true, false, "", "").map((data) => ({
        //...data,
        areaCode : data.comCode,
        areaName : data.comName,
        checked: false,
      }));
    },
    //저장
    async onClickSave() {
      //1.필수입력체크
      if (!this.validateFormRefs(this.validateRefField)) {
        return;
      }

      //작업 일자 체크
      if (compareDate(getFormattedDate(new Date()), this.detailData.dlivyDate) < 0) {
        this.errorToast("작업일자는 미래일자로 등록할 수 없습니다.");
        this.$refs.dlivyDate.focus();
        return;
      }

      //2. 그리드 필수 입력체크
      if (this.$refs.grid2.isGridModified() && !this.$refs.grid2.validate()) {
        return;
      }
      if (this.$refs.grid4.isGridModified() && !this.$refs.grid4.validate()) {
        return;
      }
      await this.apiSave();
      //4. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.saved'));
      await this.onClickSearch();
    },
    //삭제
    async onClickDelete() {
      if (!(await this.confirm(this.$t('main.popupMessage.confirmDelete')))) {
        return;
      }
      this.detailData.useFlag = false;
      await this.apiDelete();
      //4. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.deleted'));
      await this.onClickSearch();
    },
    //장비 검색 팝업
    onClickPopupSearch() {
      this.isShowSearchPopup = true;
    },

    //장비 검색 팝업 완료
    onPopupConfirmed(selData) {
      this.isShowSearchPopup = false;
      if(selData != null){
        this.detailData.eqpCode = selData.eqpCode;
        this.detailData.eqpName = selData.eqpName;
        this.detailData.eqpDiv = selData.eqpDiv;
      }
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title + ".xlsx"});
    },
    //닫기
    async onClickClose() {
      await this.clearDetailData();
      this.isOpen = false;
    },
    //리포트 출력
    onClickPrint() {
    },

    //******************************* 팝업관련    *******************************
    onClickWorkTreePopupShow() {
      this.isShowTreePopup = true;
      this.setTreeData();
    },
    async setTreeData(){
      let treeField ={};
      this.treeData = await StockCommon.getWorkCodeTreeList({useFlag:'Y'});
      this.treeData.forEach((item)=>{
        if(item.parentCode==="0")
        {
          item.parentCode=null;
        }
        item.hasChild = true;
      });
      treeField.fields = { dataSource: this.treeData, id: 'workCode', parentID: 'parentCode', text: 'workName', hasChildren: 'hasChild', selected: 'isSelected' };
      this.$nextTick(() => {
        this.$refs.workTreePopup.initShow( "작업명", treeField );
      });
    },
    onTreePopupConfirm(selectTreeView) {
      // 3단계만 선택가능..
      if(this.treeData.filter (item => item.workCode === selectTreeView.id)[0].levelDiv!=3){
        return;
      }
      this.detailData.workName = this.treeData.filter (item => item.workCode === selectTreeView.id)[0].pathDesc;
      this.detailData.workCode = selectTreeView.id;
      this.isShowTreePopup = false;
    },
    onTreePopupClose() {
      this.isShowTreePopup = false;
      this.detailData.workName = null;
      this.detailData.workCode = null;
    },

    //스펙 추가
    onClickAddSupplies(){
      this.isShowSearchPopup2 = true;
    },
    onPopupConfirmed2(args){
      this.isShowSearchPopup2 = false;

      if(args !== null){
        for (const data of args) {
          this.$refs.grid2.addRecord({
            dtlId: null,
            repairId: this.detailData.repairId,
            wrhousCode: data.wrhousCode,
            wrhousName: data.wrhousName,
            suppliesCode: data.suppliesCode,
            suppliesName: data.suppliesName,
            unit: data.unit,
            qty: data.dlivyQty,
          });
        }
      }
      //ROW- COUNT
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },
    onClickDelSupplies(){
      this.$refs.grid2.deleteRecord();
      //ROW- COUNT
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },
    onClickAddWorker() {
      this.$refs.grid4.addRecord({
        dtlId: "",
        eqpDlivyId:"",
        empno: "",
        dept: "",
        wrkrName: "",
      });
    },
    //스펙 삭제
    onClickDelWorker() {
      return this.$refs.grid4.deleteRecord();
    },

    //endregion
    //region ******************************* 공통 이벤트 *******************************
    async clearDetailData() {
      this.detailData.eqpDlivyId = null;
      this.detailData.eqpCode = null;
      this.detailData.eqpName = null;
      this.detailData.eqpDiv = null;
      this.detailData.workCode = null;
      this.detailData.workName = null;
      this.detailData.dlivyDate = getFormattedDate(new Date());
      this.detailData.startTime = null;
      this.detailData.endTime = null;
      this.detailData.remarks = null;
      this.detailData.delFlag = null;
      this.detailData.insertId = null;
      this.detailData.insertName = this.username;
      this.detailData.insertDt = null;
      this.detailData.updateDt = null;
      this.gridField2.dataSource = [];
      this.gridField3.dataSource = [];
      this.gridField4.dataSource = [];

    },
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args) {
      const {cell: {column: {field}}, node} = args;
      if (this.$refs.grid.validationRules[field]?.required) {
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onHeaderCellInfo2(args) {
      const {cell: {column: {field}}, node} = args;
      if (this.$refs.grid2.validationRules[field]?.required) {
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onHeaderCellInfo3(args) {
      const {cell: {column: {field}}, node} = args;
      if (this.$refs.grid3.validationRules[field]?.required) {
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onHeaderCellInfo4(args) {
      const {cell: {column: {field}}, node} = args;
      if (this.$refs.grid4.validationRules[field]?.required) {
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    //그리드 셀 이미지 적용
    onQueryCellInfo(args) {
      const {column: {field, allowEditing}, cell} = args;
      if (allowEditing && field) {
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    onQueryCellInfo2(args) {
      const {column: {field, allowEditing}, cell} = args;
      if (allowEditing && field) {
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    onQueryCellInfo3(args) {
      const {column: {field, allowEditing}, cell} = args;
      if (allowEditing && field) {
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    onQueryCellInfo4(args) {
      const {column: {field, allowEditing}, cell} = args;
      if (allowEditing && field) {
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    //그리드 셀 클릭
    async onGridClick(args) {
      // const {column: {field}, rowData} = args;
      // if (field === "eqpName") {
        this.isOpen = true;
        this.isNew = false;
        //상세 clear
        await this.clearDetailData();
        //상세 표시
        await this.apiDetail(args.rowData.eqpDlivyId);
      // }
    },
    //그리드 조회 완료시(카운트)
    gridComplete() {
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    gridComplete2() {
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
    gridComplete3() {
      this.gridField3.count = this.$refs.grid3.getGridBatchCount();
    },
    gridComplete4() {
      this.gridField4.count = this.$refs.grid4.getGridBatchCount();
    },

  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
/* 날자필드 readonly 글자색상*/
body .body-data .data-content::v-deep .inputDate.disabled .inputDate-input input[type="text"] {
  color: #666 !important;
}

body .appContent .body-article .article-left, body .appContent .body-article .article-right {transition: all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 20px)}
body .appContent .body-article.detail-view-opened .article-left {width: calc(50% - 0px)}
body .appContent .body-article.detail-view-opened .article-right {width: calc(50% - 0px);margin: 0}


body .body-data .field.correspondent .content .item.input {width: calc(100% - 22px);}
body .body-data .field.correspondent .content .item.button {width: 22px;}

body .body-footer .section-header {
  display: block;
  position: relative;
  margin: 0px 0px;
}

.voc-section {
  width: calc(100% + 1px) !important;
}
</style>
